@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
:root {
    --spacing: 10rem;
}

.error_error__3oSoS {
    font-size: xx-large;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    position: absolute;
    width: 100%;
}

.error_yin__3saux {
    height: 100%;
    padding-left: 10rem;
    padding-left: var(--spacing);
}

.error_imgWrapper__1-4d0 {
    height: 100%;
    width: 25%;
}

.error_messageWrapper__2tq41 {
    border-right: 5px var(--primary) solid;
    width: 25%;
    height: 75%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.error_message__cuRJU {
    margin-right: 10rem;
    margin-right: var(--spacing);
}

.error_icon__VvtRD {
    margin-right: 1rem;
}

.footer_wrapper__1GU0e {
    left: 0;
    bottom: 0;
    position: relative;
    width: 100%;
    background-color: #2e2e2e;
    color: #fff;
    border-top: 4px solid var(--primary);
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    box-shadow: -11px 0 69px -22px rgba(0, 0, 0, 0.75);
    height: 300px;
    overflow: hidden;
}

.footer_column__2MTsN {
    height: 100%;
}

#footer_left__lORpB {
}

#footer_center__3q35o {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 2rem 0 0;
}

#footer_right__aNUZO {
}

#footer_left__lORpB,
#footer_right__aNUZO {
    padding-top: 5rem;
}

.footer_links__ngS2O {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 0.5em;
    gap: 0.5em;
}

.footer_copyright__1Rck3 {
    padding-top: 1rem;
    color: var(--text-darker);
}

.footer_header__2P1_A {
    color: var(--primary-light);
}

.footer_logo__INZ5p {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition-property: -webkit-transform, -webkit-filter;
    transition-property: transform, filter;
    transition-property: transform, filter, -webkit-transform, -webkit-filter;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.footer_logo__INZ5p:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

@media (max-width: 601px) {
    .footer_wrapper__1GU0e {
        display: block;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-bottom: 3rem;
        padding-top: 1rem;
    }

    #footer_left__lORpB,
    #footer_right__aNUZO {
        padding-top: 0;
    }
}

.link_link__2hYoN {
    color: var(--text);
    text-decoration: none;
    font-weight: 800;
    transition: color ease-in-out 0.2s;
}

.link_link__2hYoN:hover {
    color: var(--primary-light);
}

:root {
    --primary: #633194;
    --primary-light: #9c3eba;
    --secondary: #333333;
    --background: #262626;
    --text: #d1d1d1;
    --text-darker: #b8b8b8;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #262626;
    background-color: var(--background);
    color: #d1d1d1;
    color: var(--text);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-corner,
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: #633194;
    background-color: var(--primary);
}

img {
    /* max-width: 100%; */
    /* height: auto; */
    object-fit: cover;
}

.wrapper {
    min-height: calc(100vh - 304px);
    position: relative;
}

