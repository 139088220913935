.wrapper {
    left: 0;
    bottom: 0;
    position: relative;
    width: 100%;
    background-color: #2e2e2e;
    color: #fff;
    border-top: 4px solid var(--primary);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    -webkit-box-shadow: -11px 0 69px -22px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -11px 0 69px -22px rgba(0, 0, 0, 0.75);
    box-shadow: -11px 0 69px -22px rgba(0, 0, 0, 0.75);
    height: 300px;
    overflow: hidden;
}

.column {
    height: 100%;
}

#left {
}

#center {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    margin: 2rem 0 0;
}

#right {
}

#left,
#right {
    padding-top: 5rem;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.copyright {
    padding-top: 1rem;
    color: var(--text-darker);
}

.header {
    color: var(--primary-light);
}

.logo {
    filter: grayscale(100%);
    transition-property: transform, filter;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.logo:hover {
    filter: grayscale(0%);
    transform: scale(1.2);
}

@media (max-width: 601px) {
    .wrapper {
        display: block;
        height: fit-content;
        padding-bottom: 3rem;
        padding-top: 1rem;
    }

    #left,
    #right {
        padding-top: 0;
    }
}
