.link {
    color: var(--text);
    text-decoration: none;
    font-weight: 800;
    transition: color ease-in-out 0.2s;
}

.link:hover {
    color: var(--primary-light);
}
